import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";

import HomePage from "./pages/HomePage";
import StockPage from "./pages/StockPage";
import Layout from "./components/layout/Layout";
import { initAmplitude } from "./util/Amplitude";
import GlobalLayout from "./components/layout/GlobalLayout";
import { ErrorBoundary } from "./components/error-boundary";
import NotFoundPage from "./pages/NotFoundPage";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <GlobalLayout />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/",
        element: <Layout />,
        children: [
          {
            path: "stocks/:stock",
            element: <StockPage />,
          },
        ],
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    initAmplitude();
  }, []);

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
