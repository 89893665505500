import React from "react";
import { ReactComponent as LinkIcon } from "../assets/icons/link.svg";
import { ReactComponent as XIcon } from "../assets/icons/x.svg";

export function Footer() {
  const footerLinks = [
    {
      href: "https://x.com/aseallseeingeye",
      label: "",
      icon: <XIcon className="w-3 h-3" />,
    },
    {
      href: "https://deocto.com",
      label: "deocto",
      icon: <LinkIcon className="w-3 h-3" />,
    },
  ];

  return (
    <footer className="flex justify-center pb-4 pt-6 gap-2 text-xs items-center">
      <nav className="flex h-4 gap-1">
        {footerLinks.map((link, index) => (
          <React.Fragment key={link.href}>
            {index > 0 && <span className="text-muted-foreground">|</span>}
            <a
              href={link.href}
              className={`text-muted-foreground hover:text-foreground ${
                link.icon ? "flex items-center gap-1" : ""
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.label}
              {link.icon}
            </a>
          </React.Fragment>
        ))}
      </nav>
    </footer>
  );
}
