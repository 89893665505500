import * as amplitude from "@amplitude/analytics-browser";
import {
  AmplitudeEventProperties,
  AmplitudeEventsEnum,
} from "../types/amplitudeEvents";

export const initAmplitude = () => {
  if (process.env.NODE_ENV === "production") {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || "", {
      defaultTracking: true,
    });
  }
};

export const logEvent = (
  eventName: AmplitudeEventsEnum,
  eventProperties?: AmplitudeEventProperties
) => {
  amplitude.track(eventName, eventProperties);
};
