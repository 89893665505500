import { useState } from "react";
import { AlertCircle, Calendar, Clock, Loader2 } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./dialog";
import { Card } from "./card";
import { useQuery } from "@tanstack/react-query";
import { fetchStockNews } from "../../util/Http";
import { CardContent } from "@mui/material";

interface NewsItem {
  symbol: string;
  published: string;
  link: string;
  title: string;
  source: string;
}

interface NewsTableProps {
  stock: string;
}

export default function StockNews({ stock }: NewsTableProps) {
  const [selectedNews, setSelectedNews] = useState<NewsItem | null>(null);

  const getSourceColor = (source: string): string => {
    const sourceColors: { [key: string]: string } = {
      OTCMarkets:
        "bg-blue-500/10 text-blue-700 dark:text-blue-400 ring-1 ring-blue-500/20",
      ACCESSWIRE:
        "bg-purple-500/10 text-purple-700 dark:text-purple-400 ring-1 ring-purple-500/20",
      GlobeNewswire:
        "bg-indigo-500/10 text-indigo-700 dark:text-indigo-400 ring-1 ring-indigo-500/20",
      iHub: "bg-emerald-500/10 text-emerald-700 dark:text-emerald-400 ring-1 ring-emerald-500/20",
      NewMediaWire:
        "bg-amber-500/10 text-amber-700 dark:text-amber-400 ring-1 ring-amber-500/20",
      "Newsfile Corp.":
        "bg-teal-500/10 text-teal-700 dark:text-teal-400 ring-1 ring-teal-500/20",
      "PR Newswire":
        "bg-rose-500/10 text-rose-700 dark:text-rose-400 ring-1 ring-rose-500/20",
      "Seeking Alpha":
        "bg-violet-500/10 text-violet-700 dark:text-violet-400 ring-1 ring-violet-500/20",
    };
    return (
      sourceColors[source] ||
      "bg-gray-500/10 text-gray-700 dark:text-gray-400 ring-1 ring-gray-500/20"
    );
  };

  const { data, isLoading, isError } = useQuery<NewsItem[]>({
    queryKey: ["StockNews", stock],
    queryFn: () => fetchStockNews(stock),
  });

  if (isLoading) {
    return (
      <Card>
        <CardContent className="flex items-center justify-center gap-2 pt-6">
          <Loader2 className="h-4 w-4 animate-spin" />
          <span>Loading News...</span>
        </CardContent>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card className="w-full">
        <CardContent className="flex flex-col justify-center items-center h-80 space-y-4">
          <div className="text-destructive/80 animate-pulse">
            <AlertCircle className="h-12 w-12" />
          </div>
          <div className="text-center space-y-2">
            <h3 className="font-medium text-lg text-destructive">
              Unable to Load Stock News
            </h3>
            <p className="text-muted-foreground text-sm max-w-[250px]">
              We encountered an issue while fetching the news data. Please try
              again later.
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data) {
    return (
      <Card className="w-full">
        <CardContent className="flex flex-col items-center justify-center py-16">
          <AlertCircle className="h-12 w-12 text-muted-foreground/50" />
          <div className="text-center mt-4">
            <h3 className="text-lg font-semibold">No News Data Available</h3>
            <p className="mt-2 text-sm text-muted-foreground">
              Currently there is no news for {stock}. This could be due to low
              trading volume or market conditions.
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  return (
    <div className="space-y-4">
      {data &&
        data.map((item: NewsItem, index: number) => (
          <Card
            key={index}
            className="p-4 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors cursor-pointer"
            onClick={() => setSelectedNews(item)}
          >
            <div className="flex flex-col space-y-2">
              <div className="flex items-center justify-start space-x-2 text-gray-400 text-sm">
                <div className="flex items-center">
                  <Calendar className="w-4 h-4" />
                  <span className="ml-1">{formatDate(item.published)}</span>
                </div>
                <div className="flex items-center">
                  <Clock className="w-4 h-4" />
                  <span className="ml-1">{formatTime(item.published)}</span>
                </div>
                <span
                  className={`px-2 py-0.5 rounded-full text-xs ${getSourceColor(item.source)}`}
                >
                  {item.source}
                </span>
              </div>
              <h3 className="font-medium line-clamp-2">{item.title}</h3>
            </div>
          </Card>
        ))}

      <Dialog open={!!selectedNews} onOpenChange={() => setSelectedNews(null)}>
        <DialogContent className="sm:max-w-2xl">
          <DialogHeader>
            <DialogTitle className="text-lg font-semibold">
              {selectedNews?.title}
            </DialogTitle>
          </DialogHeader>
          <div className="mt-4 space-y-4">
            <div className="flex items-center space-x-2 text-gray-400 dark:text-gray-300 text-sm">
              <div className="flex items-center">
                <Calendar className="w-4 h-4" />
                <span className="ml-1">
                  {selectedNews && formatDate(selectedNews.published)}
                </span>
              </div>
              <div className="flex items-center">
                <Clock className="w-4 h-4" />
                <span className="ml-1">
                  {selectedNews && formatTime(selectedNews.published)}
                </span>
              </div>
              <span
                className={`px-2 py-0.5 rounded-full text-xs ${selectedNews ? getSourceColor(selectedNews.source) : ""}`}
              >
                {selectedNews?.source}
              </span>
            </div>
            <div className="pt-4 border-t border-gray-700">
              <a
                href={selectedNews?.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors"
              >
                Read Full Article
              </a>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
