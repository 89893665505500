import { CurveType } from "@mui/x-charts";

import { DilutionData } from "../types/dilution";

export default function getDilutionDataset(data: DilutionData) {
  const seriesData = [
    {
      id: "authorizedShares",
      data: data.authorizedShares.values,
      label: "Authorized Shares",
      curve: "stepAfter" as CurveType,
      color: "#80ff00",
      showMark: false,
    },
    {
      id: "restrictedShares",
      data: data.restrictedShares.values,
      label: "Restricted Shares",
      curve: "stepAfter" as CurveType,
      color: "#66ffff",
      showMark: false,
    },
    {
      id: "dtcShares",
      data: data.dtcShares.values,
      label: "DTC Shares",
      curve: "stepAfter" as CurveType,
      color: "#ffcc00",
      showMark: false,
    },
    {
      id: "unrestrictedShares",
      data: data.unrestrictedShares.values,
      label: "Unrestricted Shares",
      curve: "stepAfter" as CurveType,
      color: "#cc00cc",
      showMark: false,
    },
  ];

  const chartDates = data.dates.map((date) => ({
    dates: new Date(date),
  }));

  return { chartDates, seriesData };
}
