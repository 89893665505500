import React, { useEffect, useState } from "react";

interface DilutionLoadingAnimationProps {
  width?: number;
  height?: number;
  restartInterval?: number;
}

const DilutionLoadingAnimation: React.FC<DilutionLoadingAnimationProps> = ({
  width = 240,
  height = 240,
  restartInterval = 6000,
}) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setKey((prevKey) => prevKey + 1);
    }, restartInterval);

    return () => clearInterval(intervalId);
  }, [restartInterval]);

  return (
    <div className="flex items-center justify-center" style={{ width, height }}>
      <svg
        key={key}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <style>
          {`
            @keyframes drawLine {
              to {
                stroke-dashoffset: 0;
              }
            }
            @keyframes fadeIn {
              to {
                opacity: 1;
              }
            }
            @keyframes scaleIn {
              to {
                transform: scale(1);
              }
            }
          `}
        </style>
        <line
          x1={width * 0.167}
          y1={height * 0.833}
          x2={width * 0.917}
          y2={height * 0.833}
          stroke="#94a3b8"
          strokeWidth="2"
          style={{ animation: "drawLine 1.5s ease-out forwards" }}
          strokeDasharray={width * 0.75}
          strokeDashoffset={width * 0.75}
        />
        <line
          x1={width * 0.167}
          y1={height * 0.833}
          x2={width * 0.167}
          y2={height * 0.167}
          stroke="#94a3b8"
          strokeWidth="2"
          style={{ animation: "drawLine 1.5s ease-out forwards" }}
          strokeDasharray={height * 0.667}
          strokeDashoffset={height * 0.667}
        />

        <circle
          cx={width * 0.292}
          cy={height * 0.708}
          r={width * 0.017}
          fill="#3b82f6"
          opacity="0"
          style={{ animation: "fadeIn 0.3s ease-out 1.5s forwards" }}
        />
        <circle
          cx={width * 0.417}
          cy={height * 0.583}
          r={width * 0.017}
          fill="#3b82f6"
          opacity="0"
          style={{ animation: "fadeIn 0.3s ease-out 1.7s forwards" }}
        />
        <circle
          cx={width * 0.542}
          cy={height * 0.667}
          r={width * 0.017}
          fill="#3b82f6"
          opacity="0"
          style={{ animation: "fadeIn 0.3s ease-out 1.9s forwards" }}
        />
        <circle
          cx={width * 0.667}
          cy={height * 0.417}
          r={width * 0.017}
          fill="#3b82f6"
          opacity="0"
          style={{ animation: "fadeIn 0.3s ease-out 2.1s forwards" }}
        />
        <circle
          cx={width * 0.792}
          cy={height * 0.5}
          r={width * 0.017}
          fill="#3b82f6"
          opacity="0"
          style={{ animation: "fadeIn 0.3s ease-out 2.3s forwards" }}
        />

        <polyline
          points={`${width * 0.292},${height * 0.708} ${width * 0.417},${height * 0.583} ${width * 0.542},${height * 0.667} ${width * 0.667},${height * 0.417} ${width * 0.792},${height * 0.5}`}
          fill="none"
          stroke="#3b82f6"
          strokeWidth="2"
          style={{ animation: "drawLine 1.5s ease-out 1.5s forwards" }}
          strokeDasharray={width * 0.75}
          strokeDashoffset={width * 0.75}
        />

        <line
          x1={width * 0.167}
          y1={height * 0.667}
          x2={width * 0.917}
          y2={height * 0.667}
          stroke="#e2e8f0"
          strokeWidth="1"
          strokeDasharray="4 4"
          opacity="0"
          style={{ animation: "fadeIn 0.5s ease-out 2.5s forwards" }}
        />
        <line
          x1={width * 0.167}
          y1={height * 0.5}
          x2={width * 0.917}
          y2={height * 0.5}
          stroke="#e2e8f0"
          strokeWidth="1"
          strokeDasharray="4 4"
          opacity="0"
          style={{ animation: "fadeIn 0.5s ease-out 2.7s forwards" }}
        />
        <line
          x1={width * 0.167}
          y1={height * 0.333}
          x2={width * 0.917}
          y2={height * 0.333}
          stroke="#e2e8f0"
          strokeWidth="1"
          strokeDasharray="4 4"
          opacity="0"
          style={{ animation: "fadeIn 0.5s ease-out 2.9s forwards" }}
        />

        {/* Y-axis labels */}
        <text
          x={width * 0.125}
          y={height * 0.833}
          textAnchor="end"
          fill="#64748b"
          fontSize={width * 0.05}
          opacity="0"
          style={{ animation: "fadeIn 0.5s ease-out 3.1s forwards" }}
        >
          0
        </text>
        <text
          x={width * 0.125}
          y={height * 0.667}
          textAnchor="end"
          fill="#64748b"
          fontSize={width * 0.05}
          opacity="0"
          style={{ animation: "fadeIn 0.5s ease-out 3.2s forwards" }}
        >
          2B
        </text>
        <text
          x={width * 0.125}
          y={height * 0.5}
          textAnchor="end"
          fill="#64748b"
          fontSize={width * 0.05}
          opacity="0"
          style={{ animation: "fadeIn 0.5s ease-out 3.3s forwards" }}
        >
          3B
        </text>
        <text
          x={width * 0.125}
          y={height * 0.333}
          textAnchor="end"
          fill="#64748b"
          fontSize={width * 0.05}
          opacity="0"
          style={{ animation: "fadeIn 0.5s ease-out 3.4s forwards" }}
        >
          5B
        </text>

        <text
          x={width * 0.5}
          y={height * 0.958}
          textAnchor="middle"
          fill="#64748b"
          fontSize={width * 0.058}
          opacity="0"
          style={{ animation: "fadeIn 0.5s ease-out 3.5s forwards" }}
        >
          Loading chart...
        </text>
      </svg>
    </div>
  );
};

export default DilutionLoadingAnimation;
