import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useAuthenticatedRequest } from "./useAuthenticatedRequest";
import { Level2 } from "../types/level2";
import { DilutionData } from "../types/dilution";

const API_STOCKS_URL = `${process.env.REACT_APP_API_BACKEND_URL}/stocks`;

export function useLevel2Data(stock: string, enabled: boolean = false) {
  const authenticatedRequest = useAuthenticatedRequest();

  return useQuery<Level2>({
    queryKey: ["level2", stock],
    queryFn: () => authenticatedRequest(`${API_STOCKS_URL}/${stock}/lvl2`),
    retry: (failureCount, error) => {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        return false;
      }
      return failureCount < 3;
    },
    enabled: enabled,
  });
}

export function useDilutionData(stock: string) {
  const authenticatedRequest = useAuthenticatedRequest();

  return useQuery<DilutionData>({
    queryKey: ["dilutionData", stock],
    queryFn: () =>
      authenticatedRequest(`${API_STOCKS_URL}/${stock}/shares-structure`),
    retry: (failureCount, error) => {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        return false;
      }
      return failureCount < 3;
    },
  });
}
