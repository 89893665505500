import React from "react";
import { CommandGroup, CommandItem, CommandEmpty } from "./command";
import { StockTier, TierDisplay } from "../../types/stock";

const TierColors: Record<StockTier, string> = {
  "OTCQX International":
    "bg-emerald-500/10 text-emerald-700 dark:text-emerald-400 ring-1 ring-emerald-500/20",
  OTCQX:
    "bg-emerald-500/10 text-emerald-700 dark:text-emerald-400 ring-1 ring-emerald-500/20",
  OTCQB:
    "bg-amber-500/10 text-amber-700 dark:text-amber-400 ring-1 ring-amber-500/20",
  "Pink Current Information":
    "bg-pink-500/10 text-pink-700 dark:text-pink-400 ring-1 ring-pink-500/20",
  "Pink Limited Information":
    "bg-red-500/10 text-red-700 dark:text-red-400 ring-1 ring-red-500/20",
  "Expert Market":
    "bg-zinc-500/10 text-zinc-700 dark:text-zinc-400 ring-1 ring-zinc-500/20",
};

interface CommandGroupComponentProps {
  stocks: Array<any>;
  inputValue: string;
  onSelect: (stockSymbol: string) => void;
}

const StocksCommandGroup: React.FC<CommandGroupComponentProps> = ({
  stocks,
  inputValue,
  onSelect,
}) => {
  const getFormattedTier = (tier: string) =>
    TierDisplay[tier as StockTier] || tier;

  const getTierBadgeColor = (tier: string) =>
    TierColors[tier as StockTier] ||
    "bg-gray-500/10 text-gray-700 dark:text-gray-400 ring-1 ring-gray-500/20";

  const getHighlightedText = (text: string) => {
    const cleanInput = inputValue?.trim().toLowerCase() ?? "";
    const cleanText = text?.toLowerCase() ?? "";
    const matchStart = cleanText.indexOf(cleanInput);

    if (matchStart === -1) return { before: text, match: "", after: "" };

    return {
      before: text.slice(0, matchStart),
      match: text.slice(matchStart, matchStart + cleanInput.length),
      after: text.slice(matchStart + cleanInput.length),
    };
  };

  if (!stocks || stocks.length === 0) {
    return (
      <CommandEmpty className="py-6 text-center text-sm">
        No matching stocks found.
      </CommandEmpty>
    );
  }

  return (
    <CommandGroup>
      {stocks.map((stock) => {
        if (!stock) return null;
        const { before, match, after } = getHighlightedText(stock.symbol);

        return (
          <CommandItem
            key={stock.symbol}
            value={stock.symbol}
            onSelect={() => onSelect(stock.symbol)}
            className="px-4 py-3 cursor-pointer hover:bg-accent group border-b last:border-b-0"
          >
            <div className="flex items-start justify-between w-full gap-6">
              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-3">
                  <span className="text-base tracking-tight">
                    {before}
                    {match && (
                      <span className="font-bold uppercase">{match}</span>
                    )}
                    {after}
                  </span>
                  <span
                    className={`inline-flex items-center justify-center h-5 px-2 rounded-full text-xs font-medium tracking-wide uppercase ${getTierBadgeColor(
                      stock.tier
                    )}`}
                  >
                    {getFormattedTier(stock.tier)}
                  </span>
                </div>
                <p className="text-sm text-muted-foreground truncate mt-1 font-medium">
                  {stock.name}
                </p>
              </div>
              <div className="text-right">
                <div className="text-base font-semibold tabular-nums">
                  ${stock.last_price}
                </div>
              </div>
            </div>
          </CommandItem>
        );
      })}
    </CommandGroup>
  );
};

export default StocksCommandGroup;
