import { FileQuestion } from "lucide-react";
import { Card, CardContent } from "./card";
import { Button } from "./button";
import { useNavigate } from "react-router-dom";

interface StockNotFoundProps {
  title?: string;
  message?: string;
  showHomeButton?: boolean;
}

export default function StockNotFound({
  title = "Stock Not Found",
  message = "We couldn't find the stock you're looking for. Please check the symbol and try again.",
  showHomeButton = true,
}: StockNotFoundProps) {
  const navigate = useNavigate();

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardContent className="flex flex-col items-center text-center py-16">
        <FileQuestion className="w-16 h-16 text-muted-foreground mb-6" />
        <h2 className="text-2xl font-semibold mb-3">{title}</h2>
        <p className="text-muted-foreground mb-8 max-w-[300px]">{message}</p>
        {showHomeButton && (
          <Button
            variant="outline"
            onClick={() => navigate("/")}
            className="min-w-[200px]"
          >
            Return to Home
          </Button>
        )}
      </CardContent>
    </Card>
  );
}
