import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import StockOverview from "./StockOverview";
import Level2Table from "./Level2Table";
import DilutionChart from "./DilutionChart";
import LockedLevel2 from "./LockedLevel2";
import LockedDilutionChart from "./LockedDilutionChart";
import StockNews from "./StockNews";
import StockSec from "./StockSec";

interface StockTabsProps {
  stockName: string;
}

export default function StockTabs({ stockName }: StockTabsProps) {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  const getCurrentTab = () => {
    const hash = location.hash.replace("#", "");
    return hash || "overview";
  };

  const handleTabChange = (value: string) => {
    if (value === "overview") {
      navigate(location.pathname);
      return;
    }
    navigate(`${location.pathname}#${value}`);
  };

  return (
    <Tabs value={getCurrentTab()} onValueChange={handleTabChange}>
      <TabsList className="grid w-full grid-cols-5">
        <TabsTrigger value="overview">Overview</TabsTrigger>
        <TabsTrigger value="level2">Level 2</TabsTrigger>
        <TabsTrigger value="dilution">Dilution</TabsTrigger>
        <TabsTrigger value="news">News</TabsTrigger>
        <TabsTrigger value="sec">SEC</TabsTrigger>
      </TabsList>

      <TabsContent value="overview">
        <StockOverview stock={stockName} />
      </TabsContent>
      <TabsContent value="news">
        <StockNews stock={stockName} />
      </TabsContent>
      <TabsContent value="sec">
        <StockSec stock={stockName} />
      </TabsContent>

      <TabsContent value="level2">
        {/* {!isAuthenticated ? (
          <LockedLevel2 />
        ) : ( */}
        <Level2Table stock={stockName} />
        {/* )} */}
      </TabsContent>
      <TabsContent value="dilution">
        {/* {!isAuthenticated ? (
          <LockedDilutionChart />
        ) : ( */}
        <DilutionChart stock={stockName} />
        {/* )} */}
      </TabsContent>
    </Tabs>
  );
}
