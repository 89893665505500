import { Lock } from "lucide-react";
import { Card, CardContent } from "./card";

interface PremiumFeatureCardProps {
  title?: string;
  message?: string;
  upgradeMessage?: string;
}

export default function PremiumFeatureCard({
  title = "Premium Feature",
  message = "This feature requires a premium subscription.",
  upgradeMessage = "Upgrade to unlock this feature and more.",
}: PremiumFeatureCardProps) {
  return (
    <Card className="w-full backdrop-blur-sm">
      <CardContent className="flex flex-col items-center text-center py-12">
        <Lock className="w-12 h-12 mb-6" />
        <h2 className="text-xl font-medium mb-3">{title}</h2>
        <p className="mb-3 text-base">{message}</p>
        <p className="text-sm">{upgradeMessage}</p>
      </CardContent>
    </Card>
  );
}
