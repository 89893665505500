import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import axios, { AxiosRequestConfig } from "axios";

export function useAuthenticatedRequest() {
  const { getAccessTokenSilently } = useAuth0();

  const authenticatedRequest = useCallback(
    async <T>(url: string, options: AxiosRequestConfig = {}): Promise<T> => {
      try {
        // const token = await getAccessTokenSilently();
        const response = await axios({
          ...options,
          url,
          headers: {
            ...options.headers,
            // Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    [getAccessTokenSilently]
  );

  return authenticatedRequest;
}
